import classnames from "classnames";
import SimpleHeader from "components/Headers/SimpleHeader";
import {
  defaultPage,
  defaultSearchTerm,
  defaultShowPerPage,
} from "constants/jsons/defaultTableHelper.json";
import { getAllTeacherSchedule } from "network/apiClassSchedule/apiClassSchedule";
import { getBranchSessionClassWiseExamListFromExamSchedule } from "network/apiExamList/apiExamList";
import {
  getFilterWiseExamMarks,
  getFilterWiseExamSchedule,
} from "network/apiMarkEntries/apiMarkEntries";
import { getAllBranch } from "network/branch/loadBranch";
import {
  branchWiseSession,
  classWiseSection,
  sessionWiseClass,
} from "network/helpers/basedOnApis";
import React from "react";
import ReactToPrint from "react-to-print";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import { HideLoader, ShowLoader } from "redux/loaderSlice";
import store from "redux/store";
import AddMarks from "./AddMarks";
import EditMarks from "./EditMarks";

const ExamResult = () => {
  const [showPerPage, setShowPerPage] = React.useState(defaultShowPerPage);
  const [page, setPage] = React.useState(defaultPage);
  const [searchTerm, setSearchTerm] = React.useState(defaultSearchTerm);
  //Filters
  const [branchName, setBranchName] = React.useState("");
  const [branchId, setBranchId] = React.useState("");
  const [sessionName, setSessionName] = React.useState("");
  const [sessionId, setSessionId] = React.useState("");
  const [className, setClassName] = React.useState("");
  const [classId, setClassId] = React.useState("");
  const [classNumeric, setClassNumeric] = React.useState("");
  const [sectionName, setSectionName] = React.useState("");
  const [sectionId, setSectionId] = React.useState("");
  const [examName, setExamName] = React.useState("");
  const [examId, setExamId] = React.useState("");
  const [subjectName, setSubjectName] = React.useState("");
  const [subjectId, setSubjectId] = React.useState("");

  const [allBranch, setAllBranch] = React.useState([]);
  const [allSession, setAllSession] = React.useState([]);
  const [allClass, setAllClass] = React.useState([]);
  const [allSection, setAllSection] = React.useState([]);
  const [allExam, setAllExam] = React.useState([]);
  const [allSubject, setAllSubject] = React.useState([]);
  const [filteredMarks, setFilteredMarks] = React.useState({});
  const [examSchedule, setExamSchedule] = React.useState([]);

  const [editStatus, setEditStatus] = React.useState(false);
  const [editableMarks, setEditableMarks] = React.useState([]);

  const [tabs, setTabs] = React.useState(1);
  const componentRef = React.useRef(null);
  const user = JSON.parse(localStorage.getItem("user"));

  const currentSession = JSON.parse(localStorage.getItem("session"));

  React.useEffect(() => {
    if (user?.role !== "superAdmin") {
      setBranchId(user?.branch?._id);
      setBranchName(user?.branch?.branchName);
      setSessionName(currentSession?.name);
      setSessionId(currentSession?.id);
    }
  }, [user, currentSession]);

  React.useEffect(() => {
    async function loadBranch() {
      const data = await getAllBranch(page, showPerPage, searchTerm);
      await setAllBranch(data?.data);
    }

    loadBranch();
  }, []);

  React.useEffect(() => {
    async function branchToSession() {
      const data = await branchWiseSession(branchId);
      await setAllSession(data?.data);
    }

    branchToSession();
  }, [branchId]);

  React.useEffect(() => {
    async function sessionToClass() {
      const data = await sessionWiseClass(sessionId);
      await setAllClass(data?.data);
    }

    sessionToClass();
  }, [sessionId]);

  React.useEffect(() => {
    async function classToSection() {
      const data = await classWiseSection(classId);
      await setAllSection(data?.data);
    }

    classToSection();
  }, [classId]);

  React.useEffect(() => {
    // async function branchSessionToExamList() {
    //     const data = await getBranchSessionWiseExamList(user?.branch?.branchName, user?.branch?._id, user?.session?.sessionName, user?.session?._id);
    //     setAllExam(data);
    // }
    async function branchSessionClassSectionToExamList() {
      const scheduleListWithExam =
        await getBranchSessionClassWiseExamListFromExamSchedule(
          branchName,
          branchId,
          sessionName,
          sessionId,
          classId,
          sectionId
        );
      const allExamFromSchedule = scheduleListWithExam?.data?.map(
        (schedule) => schedule.exam
      );
      setAllExam(allExamFromSchedule);
    }

    if (
      branchName &&
      branchId &&
      sessionName &&
      sessionId &&
      classId &&
      sectionId
    ) {
      branchSessionClassSectionToExamList();
    }
  }, [branchName, branchId, sessionName, sessionId, classId, sectionId]);

  React.useEffect(() => {
    async function branchSessionToSubject() {
      const data = await getAllTeacherSchedule(
        user?.branch?.branchName,
        user?.branch?._id,
        currentSession?.name,
        currentSession?.id,
        classId,
        sectionId,
        user?._id
      );
      if (data[0] === undefined) {
        setAllSubject([]);
      } else {
        const output = await data[0]?.saturday
          ?.concat(data[0]?.sunday)
          ?.concat(data[0]?.monday)
          ?.concat(data[0]?.wednesday)
          ?.concat(data[0]?.thursday)
          ?.concat(data[0]?.tuesday)
          ?.concat(data[0]?.friday);
        const ids = await output.map((o) => o.subjectId);
        const filtered = await output.filter(
          ({ subjectId }, index) => !ids.includes(subjectId, index + 1)
        );
        await setAllSubject(filtered);
      }
    }
    if (
      user?.branch?.branchName &&
      user?.branch?._id &&
      currentSession?.name &&
      currentSession?.id &&
      classId &&
      sectionId
    ) {
      branchSessionToSubject();
    }
  }, [sectionId]);

  const handleFilter = async () => {
    await store.dispatch(ShowLoader());
    const response = await getFilterWiseExamMarks(
      branchName,
      branchId,
      sessionName,
      sessionId,
      classId,
      sectionId,
      examId,
      subjectId
    );
    setFilteredMarks(response[0]);
    console.log(response);
    await store.dispatch(HideLoader());
  };

  React.useEffect(() => {
    if (branchId && sessionId) {
      handleFilter();
    }
  }, [page, showPerPage, searchTerm, tabs]);

  const handleEdit = async () => {
    await setEditStatus(true);
    await setTabs(2);
    const editableData = await getFilterWiseExamMarks(
      branchName,
      branchId,
      sessionName,
      sessionId,
      classId,
      sectionId,
      examId,
      subjectId
    );
    const examSchedule = await getFilterWiseExamSchedule(
      branchName,
      branchId,
      sessionName,
      sessionId,
      classId,
      sectionId,
      examId,
      subjectId
    );
    setExamSchedule(examSchedule?.examSchedule);
    setEditableMarks(editableData);
  };

  return (
    <div>
      <SimpleHeader name="Results" parentName="Exam" />
      <Container className="mt--6" fluid>
        <Card>
          <Container fluid>
            <div className="nav-wrapper">
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: tabs === 1,
                    })}
                    onClick={() => {
                      setEditStatus(false);
                      setTabs(1);
                    }}
                    role="tab"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="ni ni-cloud-download-95 mr-2" />
                    Results
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: tabs === 2,
                    })}
                    onClick={() => setTabs(2)}
                    role="tab"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="ni ni-cloud-download-95 mr-2" />
                    {editStatus === false ? "Add" : "Edit"} Marks
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Container>
          <Container fluid>
            <Card className="shadow">
              <CardBody>
                <TabContent activeTab={"tabs" + tabs}>
                  <TabPane tabId="tabs1">
                    <section>
                      <CardTitle>
                        <h3 className="mb-0">
                          <i className="ni ni-active-40 text-orange" />
                          <span
                            className="ml-2"
                            style={{ fontSize: "large", color: "orange" }}
                          >
                            Select Ground
                          </span>
                          <hr />
                        </h3>
                      </CardTitle>
                      {user?.role === "superAdmin" && (
                        <Row>
                          <Col md="6" sm="6" lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="example4cols1Input"
                              >
                                Branch
                              </label>
                              <Input
                                onChange={(e) => {
                                  const values = JSON.parse(e.target.value);
                                  setBranchName(values?.name);
                                  setBranchId(values?.id);
                                }}
                                id="exampleFormControlSelect1"
                                type="select"
                              >
                                <option
                                  selected={
                                    branchName === "" && branchId === "" && true
                                  }
                                >
                                  Select
                                </option>
                                {allBranch?.map((branch) => (
                                  <option
                                    value={JSON.stringify({
                                      name: branch?.branchName,
                                      id: branch?._id,
                                    })}
                                  >
                                    {branch?.branchName}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col md="6" sm="6" lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="example4cols1Input"
                              >
                                Session
                              </label>
                              <Input
                                onChange={(e) => {
                                  const values = JSON.parse(e.target.value);
                                  setSessionName(values?.name);
                                  setSessionId(values?.id);
                                }}
                                id="exampleFormControlSelect1"
                                type="select"
                              >
                                <option
                                  selected={
                                    sessionName === "" &&
                                    sessionId === "" &&
                                    true
                                  }
                                >
                                  Select
                                </option>
                                {allSession?.map((session) => (
                                  <option
                                    value={JSON.stringify({
                                      name: session?.sessionName,
                                      id: session?._id,
                                    })}
                                  >
                                    {session?.sessionName}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md="3" sm="3" lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example4cols1Input"
                            >
                              Class
                            </label>
                            <Input
                              onChange={(e) => {
                                const values = JSON.parse(e.target.value);
                                setClassName(values?.name);
                                setClassId(values?.id);
                              }}
                              id="exampleFormControlSelect1"
                              type="select"
                            >
                              <option
                                selected={
                                  className === "" && classId === "" && true
                                }
                              >
                                Select
                              </option>
                              {allClass?.map((cls) => (
                                <option
                                  value={JSON.stringify({
                                    name: cls?.className,
                                    id: cls?._id,
                                  })}
                                >
                                  {cls?.className}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="3" sm="3" lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example4cols1Input"
                            >
                              Section
                            </label>
                            <Input
                              onChange={(e) => {
                                const values = JSON.parse(e.target.value);
                                setSectionName(values?.name);
                                setSectionId(values?.id);
                              }}
                              id="exampleFormControlSelect1"
                              type="select"
                            >
                              <option
                                selected={
                                  sectionName === "" && sectionId === "" && true
                                }
                              >
                                Select
                              </option>
                              {allSection?.map((section) => (
                                <option
                                  value={JSON.stringify({
                                    name: section?.sectionName,
                                    id: section?._id,
                                  })}
                                >
                                  {section?.sectionName}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="3" sm="3" lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example4cols1Input"
                            >
                              Exam
                            </label>
                            <Input
                              onChange={(e) => {
                                const values = JSON.parse(e.target.value);
                                setExamName(values?.name);
                                setExamId(values?.id);
                              }}
                              id="exampleFormControlSelect1"
                              type="select"
                            >
                              <option
                                selected={
                                  examName === "" && examId === "" && true
                                }
                              >
                                Select
                              </option>
                              {allExam?.map((exam) => (
                                <option
                                  value={JSON.stringify({
                                    name: exam?.name,
                                    id: exam?._id,
                                  })}
                                >
                                  {exam?.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="3" sm="3" lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example4cols1Input"
                            >
                              Subject
                            </label>
                            <Input
                              onChange={(e) => {
                                const values = JSON.parse(e.target.value);
                                setSubjectName(values?.name);
                                setSubjectId(values?.id);
                              }}
                              id="exampleFormControlSelect1"
                              type="select"
                            >
                              <option
                                selected={
                                  subjectName === "" && subjectId === "" && true
                                }
                              >
                                Select
                              </option>
                              {allSubject?.map((subject) => (
                                <option
                                  value={JSON.stringify({
                                    name: subject?.subjectName,
                                    id: subject?.subjectId,
                                  })}
                                >
                                  {subject?.subjectName}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="text-right">
                        <Button onClick={handleFilter} color="success">
                          Filter
                        </Button>
                      </div>
                    </section>
                    <div className="mt-4 text-center">
                      <Button
                        disabled={user?.role === "academicSupport"}
                        onClick={handleEdit}
                        outline
                        color="info"
                      >
                        Edit
                      </Button>
                      <ReactToPrint
                        trigger={() => (
                          <Button
                            color="primary"
                            outline
                            size="md"
                            className="buttons-copy buttons-html5 mr-auto ml-5"
                            id="print-tooltip"
                          >
                            Print
                          </Button>
                        )}
                        content={() => componentRef.current}
                      />
                    </div>
                    <div ref={componentRef}>
                      <style type="text/css" media="print">
                        {
                          "\
                                               @page { size: landscape; }\
                                            "
                        }
                      </style>
                      <Container fluid className="mt-2">
                        <Table responsive hover bordered>
                          <thead>
                            <tr>
                              <th>Student Name</th>
                              <th>Roll</th>
                              <th>Exam</th>
                              <th>Subject</th>
                              {filteredMarks?.marks?.some(
                                (m) => m?.written
                              ) && <th>Written</th>}
                              {filteredMarks?.marks?.some(
                                (m) => m?.practical
                              ) && <th>Practical</th>}
                              {filteredMarks?.marks?.some((m) => m?.viva) && (
                                <th>Viva</th>
                              )}
                              {filteredMarks?.marks?.some(
                                (m) => m?.presentation
                              ) && <th>Presentation</th>}
                              {filteredMarks?.marks?.some((m) => m?.paper1) && (
                                <th>Paper 1</th>
                              )}
                              {filteredMarks?.marks?.some((m) => m?.paper2) && (
                                <th>Paper 2</th>
                              )}
                              {filteredMarks?.marks?.some((m) => m?.paper3) && (
                                <th>Paper 3</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {filteredMarks?.marks?.map((x, index) => (
                              <tr>
                                <td>
                                  {x?.firstName} {x?.lastName}
                                </td>
                                <td>{x?.roll}</td>
                                <td>{filteredMarks?.exam?.name}</td>
                                <td>{filteredMarks?.subject?.subjectName}</td>
                                {x?.written &&
                                  (x?.written ? (
                                    <td>{x?.written}</td>
                                  ) : (
                                    <td>-</td>
                                  ))}
                                {x?.practical &&
                                  (x?.practical ? (
                                    <td>{x?.practical}</td>
                                  ) : (
                                    <td>-</td>
                                  ))}
                                {x?.attendance &&
                                  (x?.attendance ? (
                                    <td>{x?.attendance}</td>
                                  ) : (
                                    <td>-</td>
                                  ))}
                                {x?.viva &&
                                  (x?.viva ? <td>{x?.viva}</td> : <td>-</td>)}
                                {x?.presentation &&
                                  (x?.presentation ? (
                                    <td>{x?.presentation}</td>
                                  ) : (
                                    <td>-</td>
                                  ))}
                                {x?.paper1 &&
                                  (x?.paper1 ? (
                                    <td>{x?.paper1}</td>
                                  ) : (
                                    <td>-</td>
                                  ))}
                                {x?.paper2 &&
                                  (x?.paper2 ? (
                                    <td>{x?.paper2}</td>
                                  ) : (
                                    <td>-</td>
                                  ))}
                                {x?.paper3 &&
                                  (x?.paper3 ? (
                                    <td>{x?.paper3}</td>
                                  ) : (
                                    <td>-</td>
                                  ))}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Container>
                    </div>
                  </TabPane>
                  <TabPane tabId="tabs2">
                    {editStatus === false ? (
                      <AddMarks />
                    ) : (
                      <EditMarks
                        data={editableMarks[0]}
                        examSchedule={examSchedule[0]}
                      />
                    )}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Container>
        </Card>
      </Container>
    </div>
  );
};

export default ExamResult;
