import intLogo from "assets/img/brand/SJIS_Logo.png";
import moment from "moment";
import {
  addStudentQualities,
  getOneStudentQualities,
  updateStudentQualities,
} from "network/apiStudentQualities/apiStudentQualities";
import React from "react";
import {
  Button,
  Card,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";

import { HideLoader, ShowLoader } from "redux/loaderSlice";
import store from "redux/store";
import "./markSheet.css";

const QualitySheetModal = ({
  modalOpen,
  setModalOpen,
  semester,
  studentInfo,
}) => {
  const allQualities = [
    "regularity",
    "patriotism",
    "honesty",
    "leadership",
    "discipline",
    "participation",
    "cooperation",
    "tolerance",
    "awareness",
    "punctuality",
    "handwriting",
    "homework",
  ];
  const qualityGrades = ["A+", "A", "B+", "B"];

  const [selectedQualities, setSelectedQualities] = React.useState({});

  // qualities inputs
  const [qualitiesAdd, setQualitiesAdd] = React.useState(false);
  const [qualitiesEdit, setQualitiesEdit] = React.useState(false);
  const [isFormOpen, setIsFormOpen] = React.useState(false);

  const [qualitiesUpdated, setQualitiesUpdated] = React.useState(false);
  const [qualities, setQualities] = React.useState({});

  const addQualities = () => {
    const insertData = async () => {
      if (studentInfo?.branch && studentInfo?.session) {
        store.dispatch(ShowLoader());
        await addStudentQualities({
          studentId: studentInfo?._id,
          studentRegNo: studentInfo?.regNo,
          branchId: studentInfo.branch?._id,
          branchName: studentInfo.branch?.branchName,
          sessionId: studentInfo.session?._id,
          sessionName: studentInfo.session?.sessionName,
          ...selectedQualities,
        });
        store.dispatch(HideLoader());
        setQualities({});
        setSelectedQualities({});
        setQualitiesUpdated(!qualitiesUpdated);
        setIsFormOpen(false);
        setQualitiesAdd(false);
        setQualitiesEdit(false);
      }
    };

    insertData();
    store.dispatch(HideLoader());
  };

  const editQualities = () => {
    const insertData = async () => {
      if (studentInfo?.branch && studentInfo?.session) {
        store.dispatch(ShowLoader());
        await updateStudentQualities(
          studentInfo._id,
          studentInfo.branch?.branchName,
          studentInfo.session?.sessionName,
          { ...selectedQualities }
        );
        store.dispatch(HideLoader());
        setQualities({});
        setSelectedQualities({});
        setQualitiesUpdated(!qualitiesUpdated);
        setIsFormOpen(false);
        setQualitiesAdd(false);
        setQualitiesEdit(false);
      }
    };

    insertData();
    store.dispatch(HideLoader());
  };

  React.useEffect(() => {
    const fetchData = async () => {
      setQualities({});
      setSelectedQualities({});
      if (studentInfo?.branch && studentInfo?.session) {
        try {
          console.log(
            studentInfo._id,
            studentInfo.branch?.branchName,
            studentInfo.session?.sessionName
          );
          const data = await getOneStudentQualities(
            studentInfo._id,
            studentInfo.branch?.branchName,
            studentInfo.session?.sessionName
          );
          if (data) {
            setQualities(data);
            const filterQualities = {};
            allQualities.forEach((el) => {
              if (data[el]) {
                filterQualities[el] = data[el];
              }
            });
            setSelectedQualities(filterQualities);
          } else {
            setQualities({});
            setSelectedQualities({});
          }
        } catch (error) {
          console.error("Error fetching student qualities:", error);
        }
      } else {
        setQualities({});
        setSelectedQualities({});
      }
    };

    fetchData();
  }, [studentInfo, modalOpen, qualitiesUpdated]);

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        className="modal-xl"
      >
        <div className="d-flex justify-content-between p-3">
          <div>
            <h5 className="modal-title" id="exampleModalScrollableTitle">
              Quality
            </h5>
          </div>
          <div>
            {qualities && Object.keys(qualities)?.length ? (
              <Button
                onClick={() => setQualitiesEdit(true)}
                color="primary"
                size="md"
                className="buttons-copy buttons-html5 mr-auto ml-3"
              >
                Edit Qualities
              </Button>
            ) : (
              <Button
                onClick={() => setQualitiesAdd(true)}
                color="primary"
                size="md"
                className="buttons-copy buttons-html5 mr-auto ml-3"
              >
                Add Qualities
              </Button>
            )}
          </div>
        </div>

        {(qualitiesAdd || qualitiesEdit || isFormOpen) && (
          <div className="p-3">
            <Card className="p-3">
              <section>
                <CardTitle>
                  <h3 className="mb-0">
                    <i className="ni ni-active-40 text-orange" />
                    <span
                      className="ml-2"
                      style={{ fontSize: "large", color: "orange" }}
                    >
                      Select Ground
                    </span>
                    <hr />
                  </h3>
                </CardTitle>
                {
                  <Row>
                    {allQualities?.map((el) => (
                      <Col sm="6" md="6" lg="3">
                        <FormGroup>
                          <label
                            style={{ textTransform: "capitalize" }}
                            className="form-control-label"
                          >
                            {el === "homework"
                              ? semester === "1stSemester" ||
                                semester === "2ndSemester"
                                ? "Music"
                                : "Homework"
                              : el}
                          </label>
                          <Input
                            defaultValue={qualities ? qualities[el] : ""}
                            value={selectedQualities[el]}
                            onChange={(e) =>
                              setSelectedQualities({
                                ...selectedQualities,
                                [el]: e.target.value,
                              })
                            }
                            type="select"
                          >
                            <option disabled selected hidden>
                              Select
                            </option>
                            {qualityGrades?.map((grade) => (
                              <option value={grade}>{grade}</option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    ))}
                  </Row>
                }
                <div className="text-right mt-3">
                  {(qualities && Object.keys(qualities)?.length) ||
                  qualitiesEdit ? (
                    <Button onClick={() => editQualities()} color="success">
                      Update
                    </Button>
                  ) : (
                    <Button onClick={() => addQualities()} color="success">
                      Save
                    </Button>
                  )}
                </div>
              </section>
            </Card>
          </div>
        )}

        {
          <div
            style={{ margin: "8px" }}
            className="modal-body modal-body-custom"
          >
            <div className="text-center">
              <img style={{ width: "80px" }} src={intLogo} alt="logo" />
              <h4>St. Joseph International School</h4>
            </div>
            <div className="customResult">
              <div className="part1">
                <div className="std_info_grading">
                  <div className="std_info">
                    <h4>
                      <u>Student's Information</u>
                    </h4>
                    <div>
                      <div>Student's ID</div>
                      <div>: {studentInfo?.regNo}</div>
                    </div>
                    <div>
                      <div>Student's Name</div>
                      <div>
                        : {studentInfo?.firstName} {studentInfo?.lastName}
                      </div>
                    </div>
                    <div>
                      <div>Date of Birth</div>
                      <div>
                        :{" "}
                        {moment(new Date(studentInfo?.dob)).format(
                          "DD-MMM-yyyy"
                        )}
                      </div>
                    </div>
                    <div>
                      <div>Gender</div>
                      <div>
                        : {studentInfo?.gender === "female" ? "Female" : "Male"}
                      </div>
                    </div>
                    <div>
                      <div>Academic Session</div>
                      <div>: {studentInfo?.session?.sessionName}</div>
                    </div>
                    <div>
                      <div>Roll</div>
                      <div>: {studentInfo?.roll}</div>
                    </div>
                    <div>
                      <div>Standard</div>
                      <div>
                        : {studentInfo?.class?.className} (
                        {studentInfo?.class?.classNumeric})
                      </div>
                    </div>
                    <div>
                      <div>Section</div>
                      <div>: {studentInfo?.section?.sectionName}</div>
                    </div>
                    <div>
                      <div>Name of Examination</div>
                      <div>
                        : {semester === "1stSemester" ? "Half Yearly" : ""}
                        {semester === "2ndSemester" ? "Annual" : ""}
                        {semester === "half_yearly" ? "Half Yearly" : ""}
                        {semester === "yearly" ? "Annual" : ""}
                        {semester === "pre_qualifying" ? "Pre Qualifying" : ""}
                        {semester === "qualifying" ? "qualifying" : ""}
                        {semester === "preparation"
                          ? "O Level Preparation Examination"
                          : ""}
                        {semester === "pre_cambridge" ? "pre_cambridge" : ""}
                        <span> Examination </span>
                        {studentInfo?.session?.sessionName}
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div>
              <p className="ndPart"></p>
            </div>
            <div className="ndPart d-flex justify-content-between p-3">
              <div>
                <table className="customTable">
                  <thead>
                    <tr>
                      <th className="customThg" scope="col">
                        Grade
                      </th>
                      <th className="customThg" scope="col">
                        Meaning
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="customTdg">A+</td>
                      <td className="customTdg">Excellent</td>
                    </tr>
                    <tr>
                      <td className="customTdg">A</td>
                      <td className="customTdg">Good</td>
                    </tr>
                    <tr>
                      <td className="customTdg">B+</td>
                      <td className="customTdg">Satisfactory</td>
                    </tr>
                    <tr>
                      <td className="customTdg">B</td>
                      <td className="customTdg">Needs Improvement</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className="customTable">
                  <thead>
                    <tr>
                      <td className="customThg">Regularity</td>
                      <td style={{ width: "120px" }} className="customThg">
                        {qualities?.regularity || ""}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="customTdg">Patriotism</td>
                      <td className="customTdg">
                        {qualities?.patriotism || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Honesty</td>
                      <td className="customTdg">{qualities?.honesty || ""}</td>
                    </tr>
                    <tr>
                      <td className="customTdg">Leadership</td>
                      <td className="customTdg">
                        {qualities?.leadership || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Discipline</td>
                      <td className="customTdg">
                        {qualities?.discipline || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Cooperation</td>
                      <td className="customTdg">
                        {qualities?.cooperation || ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className="customTable">
                  <thead>
                    <tr>
                      <td className="customThg">Participation</td>
                      <td style={{ width: "120px" }} className="customThg">
                        {qualities?.participation || ""}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="customTdg">Tolerance</td>
                      <td className="customTdg">
                        {qualities?.tolerance || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Awareness</td>
                      <td className="customTdg">
                        {qualities?.awareness || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Punctuality</td>
                      <td className="customTdg">
                        {qualities?.punctuality || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">Handwriting</td>
                      <td className="customTdg">
                        {qualities?.handwriting || ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="customTdg">
                        {semester === "1stSemester" ||
                        semester === "2ndSemester"
                          ? "Music"
                          : "Homework"}
                      </td>
                      <td className="customTdg">{qualities?.homework || ""}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        }
        <div className="modal-footer">
          <Button
            color="primary"
            outline
            onClick={() => {
              setQualities({});
              setSelectedQualities({});
              setModalOpen(!modalOpen);
            }}
            type="button"
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default QualitySheetModal;
