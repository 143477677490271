import intLogo from "assets/img/brand/SJIS_Logo.png";
import SimpleHeader from "components/Headers/SimpleHeader";
import {
  defaultPage,
  defaultSearchTerm,
  defaultShowPerPage,
} from "constants/jsons/defaultTableHelper.json";
import { getAllBranch } from "network/branch/loadBranch";
import {
  branchWiseSession,
  classWiseSection,
  sessionWiseClass,
} from "network/helpers/basedOnApis";
import { getTabulationSemester } from "network/tabulation/apiTabulation";
import React from "react";
import ReactToPrint from "react-to-print";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import { HideLoader, ShowLoader } from "redux/loaderSlice";
import store from "redux/store";
import "../tabulation.css";

function getGrade(percentage) {
  percentage = Math.round(percentage);
  if (percentage >= 95) {
    return "A+";
  }
  if (percentage < 95 && percentage >= 90) {
    return "A";
  }
  if (percentage < 90 && percentage >= 85) {
    return "B+";
  }
  if (percentage < 85 && percentage >= 80) {
    return "B";
  }
  if (percentage < 80 && percentage >= 75) {
    return "C+";
  }
  if (percentage < 75 && percentage >= 70) {
    return "C";
  }
  if (percentage < 70 && percentage >= 65) {
    return "D+";
  }
  if (percentage < 65 && percentage >= 60) {
    return "D";
  }
  if (percentage < 60) {
    return "U";
  }
}

function gradeLength(grades) {
  const uniqueGrades = Array.from(new Set(grades));
  const gradeLengths = uniqueGrades?.map((grade) => ({
    grade,
    length: grades.filter((g) => g === grade)?.length,
  }));
  return gradeLengths;
}

const SingleMarkResult = ({ markData }) => {
  const total = markData?.total || 0;
  const grade = markData?.grade || "-";
  return (
    <>
      <p
        style={{ fontSize: "16px", color: "black" }}
        className="mb-0 pb-0 font-weight-500"
      >
        {Math.round(total)}
      </p>

      <span className="mb-0 mt-0 text-center">
        <hr
          style={{ color: "black" }}
          className="w-25 mb-0 mt-0 divider font-weight-500"
        />
      </span>

      <p
        className="mb-0 font-weight-500"
        style={{ fontSize: "16px", color: "black" }}
      >
        {grade}
      </p>
    </>
  );
};

const TabulationYearlyNew = () => {
  const [branchName, setBranchName] = React.useState("");
  const [branchId, setBranchId] = React.useState("");
  const [sessionName, setSessionName] = React.useState("");
  const [sessionId, setSessionId] = React.useState("");
  const [className, setClassName] = React.useState("");
  const [classId, setClassId] = React.useState("");
  const [classNumeric, setClassNumeric] = React.useState("");
  const [sectionName, setSectionName] = React.useState("");
  const [sectionId, setSectionId] = React.useState("");

  const [allBranch, setAllBranch] = React.useState([]);
  const [allSession, setAllSession] = React.useState([]);
  const [allClass, setAllClass] = React.useState([]);
  const [allSection, setAllSection] = React.useState([]);
  const [results, setResults] = React.useState([]);

  const [isProcessComplete, setIsProcessComplete] = React.useState(false);

  const componentRef = React.useRef(null);

  const uniqueSubjects = [];
  let finalSubjects = results
    ?.map((x) => x?.marks)
    ?.flat()
    ?.filter((element) => {
      const isDuplicate = uniqueSubjects.includes(element.subjectId);

      if (!isDuplicate) {
        uniqueSubjects.push(element.subjectId);

        return true;
      }

      return false;
    });

  const user = JSON.parse(localStorage.getItem("user"));

  const currentSession = JSON.parse(localStorage.getItem("session"));

  React.useEffect(() => {
    if (user?.role !== "superAdmin") {
      setBranchId(user?.branch?._id);
      setBranchName(user?.branch?.branchName);
      setSessionName(currentSession?.name);
      setSessionId(currentSession?.id);
    }
  }, [user, currentSession]);

  React.useEffect(() => {
    async function loadBranch() {
      const data = await getAllBranch(
        defaultPage,
        defaultShowPerPage,
        defaultSearchTerm
      );
      setAllBranch(data?.data);
    }

    loadBranch();
  }, []);

  React.useEffect(() => {
    async function branchToSession() {
      const data = await branchWiseSession(branchId);
      setAllSession(data?.data);
    }

    branchToSession();
  }, [branchId]);

  React.useEffect(() => {
    async function sessionToClass() {
      const data = await sessionWiseClass(sessionId);
      const result = data?.data?.filter(
        (item) => item.classNumeric >= 8 && item.classNumeric <= 9
      );
      setAllClass(result);
    }

    sessionToClass();
  }, [sessionId]);

  React.useEffect(() => {
    async function classToSection() {
      const data = await classWiseSection(classId);
      setAllSection(data?.data);
    }

    classToSection();
  }, [classId]);

  const getShortName = (subject) => {
    if (subject === "English Language") {
      return "Eng Lang";
    } else if (subject === "Spelling and Dictation") {
      return "Spelling";
    } else if (subject === "English Literature") {
      return "Eng Lit";
    } else if (subject === "Mathematics") {
      return "Math";
    } else if (subject === "Additional Math") {
      return "Add Math";
    } else if (subject === "Business Studies") {
      return "Business";
    } else if (subject === "Bangladesh Studies") {
      return "B. Study";
    } else if (subject === "Computer Science") {
      return "Computer";
    } else {
      return subject;
    }
  };

  const handleFilter = async () => {
    try {
      await store.dispatch(ShowLoader());
      setIsProcessComplete(false);
      const tabulationYearly = await getTabulationSemester(
        branchName,
        branchId,
        sessionName,
        sessionId,
        classId,
        classNumeric,
        sectionId,
        "yearly"
      );
      const tabulationHalfYearly = await getTabulationSemester(
        branchName,
        branchId,
        sessionName,
        sessionId,
        classId,
        classNumeric,
        sectionId,
        "half_yearly"
      );

      const processTabulation = (tabulation = []) => {
        return tabulation?.map((document) => {
          const grades = [];
          return {
            ...document,
            grade: grades,
            marks: document?.marks?.map((subject) => {
              const ratio40totalMarks =
                (subject?.classTest || 0) +
                (subject?.assignment || 0) +
                (subject?.sba || 0);
              const ratio40totalFullMarks =
                (subject?.classTestFullMark || 0) +
                (subject?.assignmentFullMark || 0) +
                (subject?.sbaFullMark || 0);

              const ratio60totalMarks = subject?.term || 0;
              const ratio60totalFullMarks = subject?.termFullMark || 0;

              const converted40 =
                (ratio40totalMarks / ratio40totalFullMarks) * 40;
              const converted60 =
                (ratio60totalMarks / ratio60totalFullMarks) * 60;

              const total = converted40 + converted60;
              const grade = total > 0 ? getGrade(total) : "-";

              if (total) {
                grades.push(grade);
              }

              return {
                ...subject,
                total,
                grade,
              };
            }),
          };
        });
      };

      const processCombinedTabulation = (
        tabulationYearly = [],
        tabulationHalfYearly = []
      ) => {
        return tabulationYearly?.map((yearlyDocument) => {
          const grades = [];
          const correspondingHalfYearly = tabulationHalfYearly?.find(
            (halfYearlyDocument) =>
              halfYearlyDocument?.regNo === yearlyDocument.regNo &&
              halfYearlyDocument?.roll === yearlyDocument.roll
          );

          const combinedMarks = yearlyDocument?.marks?.map((yearlySubject) => {
            const halfYearlySubject = correspondingHalfYearly?.marks.find(
              (subject) => subject.subjectId === yearlySubject.subjectId
            );

            const averageTotal = halfYearlySubject
              ? ((yearlySubject?.total || 0) +
                  (halfYearlySubject?.total || 0)) /
                  2 || 0
              : yearlySubject?.total || 0;

            const averageGrade =
              averageTotal > 0 ? getGrade(averageTotal) : "-";

            if (averageTotal) {
              grades.push(averageGrade);
            }

            return {
              ...yearlySubject,
              total: averageTotal,
              grade: averageGrade,
            };
          });

          return {
            ...yearlyDocument,
            grade: grades,
            marks: combinedMarks,
          };
        });
      };

      const processedYearly = processTabulation(tabulationYearly);
      const processedHalfYearly = processTabulation(tabulationHalfYearly);

      const combinedResults = processCombinedTabulation(
        processedYearly,
        processedHalfYearly
      );

      const results = combinedResults?.map((document) => {
        return {
          ...document,
          total:
            document?.marks?.reduce((sum, subject) => sum + subject.total, 0) ||
            0,
        };
      });

      results.sort((a, b) => b.total - a.total);

      setResults(results);
    } catch (error) {
      console.log(error);
    } finally {
      await store.dispatch(HideLoader());
      setIsProcessComplete(true);
    }
  };

  return (
    <div>
      <SimpleHeader name="Result" parentName="Tabulation" />
      <Container className="mt--6" fluid>
        <Card className="p-4">
          <CardTitle>
            <h3 className="mb-0">
              <i className="ni ni-active-40 text-orange" />
              <span
                className="ml-2"
                style={{ fontSize: "large", color: "orange" }}
              >
                Select Ground
              </span>
              <hr />
            </h3>
          </CardTitle>
          <Row>
            <Col md="6" sm="6" lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example4cols1Input"
                >
                  Branch
                </label>
                <Input
                  onChange={(e) => {
                    const values = JSON.parse(e.target.value);
                    setBranchName(values?.name);
                    setBranchId(values?.id);
                  }}
                  id="exampleFormControlSelect1"
                  type="select"
                >
                  <option
                    selected={branchName === "" && branchId === "" && true}
                  >
                    Select
                  </option>
                  {allBranch?.map((branch) => (
                    <option
                      key={branch?._id}
                      value={JSON.stringify({
                        name: branch?.branchName,
                        id: branch?._id,
                      })}
                    >
                      {branch?.branchName}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md="6" sm="6" lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example4cols1Input"
                >
                  Session
                </label>
                <Input
                  onChange={(e) => {
                    const values = JSON.parse(e.target.value);
                    setSessionName(values?.name);
                    setSessionId(values?.id);
                  }}
                  id="exampleFormControlSelect1"
                  type="select"
                >
                  <option
                    selected={sessionName === "" && sessionId === "" && true}
                  >
                    Select
                  </option>
                  {allSession?.map((session) => (
                    <option
                      value={JSON.stringify({
                        name: session?.sessionName,
                        id: session?._id,
                      })}
                    >
                      {session?.sessionName}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6" sm="6" lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example4cols1Input"
                >
                  Class
                </label>
                <Input
                  onChange={(e) => {
                    const values = JSON.parse(e.target.value);
                    setClassName(values?.name);
                    setClassId(values?.id);
                    setClassNumeric(values?.numeric);
                  }}
                  id="exampleFormControlSelect1"
                  type="select"
                >
                  <option selected={className === "" && classId === "" && true}>
                    Select
                  </option>
                  {allClass?.map((cls) => (
                    <option
                      value={JSON.stringify({
                        name: cls?.className,
                        id: cls?._id,
                        numeric: cls?.classNumeric,
                      })}
                    >
                      {cls?.className}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md="6" sm="6" lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example4cols1Input"
                >
                  Section
                </label>
                <Input
                  onChange={(e) => {
                    const values = JSON.parse(e.target.value);
                    setSectionName(values?.name);
                    setSectionId(values?.id);
                  }}
                  id="exampleFormControlSelect1"
                  type="select"
                >
                  <option
                    selected={sectionName === "" && sectionId === "" && true}
                  >
                    Select
                  </option>
                  {allSection?.map((section) => (
                    <option
                      value={JSON.stringify({
                        name: section?.sectionName,
                        id: section?._id,
                      })}
                    >
                      {section?.sectionName}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <div className="text-right">
            {isProcessComplete && results?.length > 0 && (
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="warning"
                    size="sm"
                    className="buttons-copy buttons-html5"
                    id="print-tooltip"
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            )}
            <Button size="sm" onClick={handleFilter} color="success">
              Filter
            </Button>
          </div>
        </Card>
      </Container>
      <Container fluid>
        {isProcessComplete && (
          <>
            {results?.length > 0 ? (
              <Card>
                <div>
                  <div
                    style={{
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      fontSize: "16px",
                    }}
                    ref={componentRef}
                    className="modal-body printable-content"
                  >
                    <div className="text-center">
                      <img style={{ width: "80px" }} src={intLogo} alt="logo" />
                      <h4>St. Joseph International School</h4>
                      <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                        Tabulation Sheet, Yearly Examination
                      </p>
                      <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                        Class: {className} {sectionName}
                      </p>
                    </div>
                    <div className="">
                      <div style={{ display: "flex" }}>
                        <Table bordered responsive size="sm" className="">
                          <thead>
                            <tr>
                              <th
                                style={{ fontSize: "16px" }}
                                className="text-center align-middle font-weight-bold customTd"
                              >
                                Roll
                              </th>
                              <th
                                style={{ fontSize: "16px" }}
                                className="text-center align-middle font-weight-bold customTd"
                              >
                                Student Name
                              </th>
                              {finalSubjects?.map((s) => (
                                <th
                                  style={{ maxHeight: "16px" }}
                                  key={s?.subjectId}
                                  className="text-center align-middle font-weight-bold customTd"
                                >
                                  <p
                                    className="font-weight-bold mb-0 pb-0"
                                    style={{ fontSize: "16px" }}
                                  >
                                    {getShortName(s?.subject)}
                                  </p>
                                  <span className="mb-0 mt-0 text-center">
                                    <hr
                                      style={{ color: "black" }}
                                      className="w-25 mb-0 mt-0 divider font-weight-500"
                                    />
                                  </span>

                                  <p
                                    className="font-weight-bold mb-0"
                                    style={{ fontSize: "16px" }}
                                  >
                                    Grade
                                  </p>
                                </th>
                              ))}

                              <th
                                style={{ fontSize: "16px" }}
                                className="text-center align-middle font-weight-bold customTd"
                              >
                                Total
                              </th>
                              <th
                                style={{ fontSize: "16px" }}
                                className="text-center align-middle font-weight-bold customTd"
                              >
                                Grade Count
                              </th>
                              <th
                                style={{ fontSize: "16px" }}
                                className="text-center align-middle font-weight-bold customTd"
                              >
                                Position
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {results?.map((x, i) => (
                              <tr key={x?._id}>
                                <td
                                  className="text-center align-middle customTd"
                                  style={{ fontSize: "16px" }}
                                >
                                  {x?.roll}
                                </td>
                                <td
                                  className="text-center align-middle customTd"
                                  style={{ fontSize: "16px" }}
                                >
                                  {x?.firstName} {x?.lastName}
                                </td>
                                {finalSubjects?.map((s) => (
                                  <td
                                    key={s?.subjectId}
                                    style={{ fontSize: "16px" }}
                                    className="text-center align-middle customTd"
                                  >
                                    {x?.marks?.find(
                                      (sub) => s?.subjectId === sub?.subjectId
                                    )?.total ? (
                                      <SingleMarkResult
                                        markData={x?.marks?.find(
                                          (sub) =>
                                            s?.subjectId === sub?.subjectId
                                        )}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                ))}
                                <td
                                  className="text-center align-middle customTd"
                                  style={{ fontSize: "16px" }}
                                >
                                  {Math.round(x?.total)}
                                </td>
                                <td
                                  className="text-center align-middle customTd"
                                  style={{ fontSize: "16px" }}
                                >
                                  <span>
                                    {gradeLength(x?.grade)?.map(
                                      ({ grade, length }, index) => (
                                        <React.Fragment key={grade}>
                                          {grade}: {length}
                                          {index !==
                                          gradeLength(x?.grade)?.length - 1
                                            ? ", "
                                            : ""}
                                        </React.Fragment>
                                      )
                                    )}
                                  </span>
                                </td>
                                <td
                                  className="text-center align-middle customTd"
                                  style={{ fontSize: "16px" }}
                                >
                                  {i + 1}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <style jsx>{`
                      @media print {
                        @page {
                          size: 1754px 1240px; /* At 150 DPI/PPI */
                          margin: 0;
                        }

                        .printable-content {
                          width: 100%;
                          height: 100%;
                        }

                        .page-break {
                          page-break-before: always;
                        }
                      }
                    `}</style>
                  </div>
                </div>
              </Card>
            ) : (
              <div className="text-center mt-4">
                <h3>No Data Found</h3>
              </div>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default TabulationYearlyNew;
